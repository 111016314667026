import request from '@/utils/request'

export const getList = data => {
  return request({
    method: 'post',
    url: '/problem/getList',
    data
  })
}
// /contact/getContactConfig获取联系方式配置
export const getContactConfig = data => {
  return request({
    method: 'post',
    url: '/contact/getContactConfig',
    params: data
  })
}
